import { default as indexkoivs8q9ZbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexfKa4cA2aWoMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as indexk89KPbqs69Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue?macro=true";
import { default as _91id_93IWwYrHPVI3Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93XVGWHcUqiOMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexsnyXHIn6tbMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/index.vue?macro=true";
import { default as islands_45pageHIB3DRLxLuMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue?macro=true";
import { default as indexpzCPJ6kWkIMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue?macro=true";
import { default as index22YwN5CyGpMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator-debug/index.vue?macro=true";
import { default as index2NYjB9fafiMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator/index.vue?macro=true";
import { default as indexYVga7PhUChMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexcdn7sLSV3BMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/pharmacy/[id]/index.vue?macro=true";
import { default as index9tPdmztHdEMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as indexlIvEVVgGTbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue?macro=true";
import { default as component_45stub59CDIxQOB7Meta } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub59CDIxQOB7 } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexkoivs8q9ZbMeta?.name ?? "slug",
    path: indexkoivs8q9ZbMeta?.path ?? "/:slug(.*)*",
    meta: indexkoivs8q9ZbMeta || {},
    alias: indexkoivs8q9ZbMeta?.alias || [],
    redirect: indexkoivs8q9ZbMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfKa4cA2aWoMeta?.name ?? "article-slug",
    path: indexfKa4cA2aWoMeta?.path ?? "/article/:slug(.*)*",
    meta: indexfKa4cA2aWoMeta || {},
    alias: indexfKa4cA2aWoMeta?.alias || [],
    redirect: indexfKa4cA2aWoMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk89KPbqs69Meta?.name ?? "event-nid-slug",
    path: indexk89KPbqs69Meta?.path ?? "/event/:nid()/:slug(.*)*",
    meta: indexk89KPbqs69Meta || {},
    alias: indexk89KPbqs69Meta?.alias || [],
    redirect: indexk89KPbqs69Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IWwYrHPVI3Meta?.name ?? "form-id",
    path: _91id_93IWwYrHPVI3Meta?.path ?? "/form/:id()",
    meta: _91id_93IWwYrHPVI3Meta || {},
    alias: _91id_93IWwYrHPVI3Meta?.alias || [],
    redirect: _91id_93IWwYrHPVI3Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XVGWHcUqiOMeta?.name ?? "form-confirmation-id",
    path: _91id_93XVGWHcUqiOMeta?.path ?? "/form/confirmation/:id()",
    meta: _91id_93XVGWHcUqiOMeta || {},
    alias: _91id_93XVGWHcUqiOMeta?.alias || [],
    redirect: _91id_93XVGWHcUqiOMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsnyXHIn6tbMeta?.name ?? "index",
    path: indexsnyXHIn6tbMeta?.path ?? "/",
    meta: indexsnyXHIn6tbMeta || {},
    alias: indexsnyXHIn6tbMeta?.alias || [],
    redirect: indexsnyXHIn6tbMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/index.vue").then(m => m.default || m)
  },
  {
    name: islands_45pageHIB3DRLxLuMeta?.name ?? "islands-page",
    path: islands_45pageHIB3DRLxLuMeta?.path ?? "/islands-page",
    meta: islands_45pageHIB3DRLxLuMeta || {},
    alias: islands_45pageHIB3DRLxLuMeta?.alias || [],
    redirect: islands_45pageHIB3DRLxLuMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue").then(m => m.default || m)
  },
  {
    name: indexpzCPJ6kWkIMeta?.name ?? "media-id",
    path: indexpzCPJ6kWkIMeta?.path ?? "/media/:id()",
    meta: indexpzCPJ6kWkIMeta || {},
    alias: indexpzCPJ6kWkIMeta?.alias || [],
    redirect: indexpzCPJ6kWkIMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index22YwN5CyGpMeta?.name ?? "navigator-debug",
    path: index22YwN5CyGpMeta?.path ?? "/navigator-debug",
    meta: index22YwN5CyGpMeta || {},
    alias: index22YwN5CyGpMeta?.alias || [],
    redirect: index22YwN5CyGpMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator-debug/index.vue").then(m => m.default || m)
  },
  {
    name: index2NYjB9fafiMeta?.name ?? "navigator",
    path: index2NYjB9fafiMeta?.path ?? "/navigator",
    meta: index2NYjB9fafiMeta || {},
    alias: index2NYjB9fafiMeta?.alias || [],
    redirect: index2NYjB9fafiMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator/index.vue").then(m => m.default || m)
  },
  {
    name: indexYVga7PhUChMeta?.name ?? "node-nid",
    path: indexYVga7PhUChMeta?.path ?? "/node/:nid()",
    meta: indexYVga7PhUChMeta || {},
    alias: indexYVga7PhUChMeta?.alias || [],
    redirect: indexYVga7PhUChMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcdn7sLSV3BMeta?.name ?? "pharmacy-id",
    path: indexcdn7sLSV3BMeta?.path ?? "/pharmacy/:id()",
    meta: indexcdn7sLSV3BMeta || {},
    alias: indexcdn7sLSV3BMeta?.alias || [],
    redirect: indexcdn7sLSV3BMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/pharmacy/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9tPdmztHdEMeta?.name ?? "press-release-slug",
    path: index9tPdmztHdEMeta?.path ?? "/press-release/:slug(.*)*",
    meta: index9tPdmztHdEMeta || {},
    alias: index9tPdmztHdEMeta?.alias || [],
    redirect: index9tPdmztHdEMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlIvEVVgGTbMeta?.name ?? "search",
    path: indexlIvEVVgGTbMeta?.path ?? "/search",
    meta: indexlIvEVVgGTbMeta || {},
    alias: indexlIvEVVgGTbMeta?.alias || [],
    redirect: indexlIvEVVgGTbMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub59CDIxQOB7Meta?.name ?? undefined,
    path: component_45stub59CDIxQOB7Meta?.path ?? "/cart",
    meta: component_45stub59CDIxQOB7Meta || {},
    alias: component_45stub59CDIxQOB7Meta?.alias || [],
    redirect: component_45stub59CDIxQOB7Meta?.redirect,
    component: component_45stub59CDIxQOB7
  },
  {
    name: component_45stub59CDIxQOB7Meta?.name ?? undefined,
    path: component_45stub59CDIxQOB7Meta?.path ?? "/user/login",
    meta: component_45stub59CDIxQOB7Meta || {},
    alias: component_45stub59CDIxQOB7Meta?.alias || [],
    redirect: component_45stub59CDIxQOB7Meta?.redirect,
    component: component_45stub59CDIxQOB7
  }
]